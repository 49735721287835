import * as React from 'react';
import MaterialTable, { Column } from '@material-table/core';
import mbaas from '../../provider/Mbaas';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

import {
  Confirm,
  useCreate,
  useDelete,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { TextField, Autocomplete } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import { differenceWith, toPairs, isEqual, fromPairs } from 'lodash';

interface IProductPricingItem {
  id: string;
  value: number;
  account_id: string;
  account_label: string;
  account_id_data: { id: string; label: string };
  account_list: { id: string; label: string };
  method: string;
  product_pricing_id: string;
  is_available: boolean;
}

const ProductPricing = () => {
  const record = useRecordContext();
  const mapData = (rawData: any) => {
    return rawData.map((x: any) =>
      Object.assign({
        product_item_id: record.id,
        ...x,
        product_pricing_id: x.id,
        account_id: x.account_id_data.id,
        account_label: x.account_id_data.label,
      })
    );
  };

  const originData = mapData(record.product_pricings_product_item_id_list.data);

  const [tableData, setTableData] = React.useState(originData);
  //
  const [currentSelectedAccount, setCurrentSelectedAccount] = React.useState<any>([]);
  //
  const [create] = useCreate();
  const [update] = useUpdate();
  const [deleteOne] = useDelete();
  const refresh = useRefresh();
  const notify = useNotify();
  const [cOpen, setCOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitConf, setSubmitConf] = React.useState({ method: '', data: {} });
  const handleDialogClose = () => setCOpen(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      console.log('ERROR-TOTP', error?.error);
      if (data === 'OK') {
        if (submitConf.method === 'add-product-pricing') {
          create(
            'product-pricings',
            { data: submitConf.data },
            {
              onSuccess: async () => {
                await initData();
                notify('Success Add Pricings', {
                  type: 'success',
                });
              },
              onError(error, variables, context) {
                notify('An error has occurred. Please try again later', {
                  type: 'error',
                });
              },
            }
          );
        }

        if (submitConf.method === 'update-product-pricing') {
          update(
            'product-pricings',
            //@ts-ignore
            {
              //@ts-ignore
              id: submitConf?.data?.product_pricing_id,
              //@ts-ignore
              data: submitConf.data?.dataProductPricing,
              //@ts-ignore
              previousData: submitConf.data?.oldData,
            },
            {
              onSuccess: async () => {
                await initData();
                notify('Success update data', {
                  type: 'success',
                });
              },
              onError(error, variables, context) {
                notify('An error has occurred. Please try again later', {
                  type: 'error',
                });
              },
            }
          );
        }

        if (submitConf.method === 'delete-product-pricing') {
          deleteOne(
            'product-pricings',
            //@ts-ignore
            {
              //@ts-ignore
              id: submitConf?.data?.product_pricing_id,
              //@ts-ignore
              previousData: submitConf.data?.oldData,
            },
            {
              onSuccess: async () => {
                await initData();
                notify('Success delete data', {
                  type: 'success',
                });
              },
              onError(error, variables, context) {
                notify('An error has occurred. Please try again later', {
                  type: 'error',
                });
              },
            }
          );
        }
        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);

        notify('An error has occurred. Please try again later', {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
    setIsLoading(false);
  };
  //

  // initData here
  const initData = async () => {
    const { getProductItemsById } = await mbaas.client.gql.query({
      query: gql`
        query ProductItem($id: UUID!) {
          getProductItemsById(id: $id) {
            id
            created_at
            item_key
            product_pricings_product_item_id_list(limit: 100) {
              data {
                id
                account_id_data {
                  id
                  label
                }
                method
                value
                is_available
              }
            }
          }
        }
      `,
      variables: {
        id: record.id,
      },
    });
    //
    const mappedData = mapData(getProductItemsById.product_pricings_product_item_id_list.data);
    const selectedAccount = mappedData.map((el: any) => {
      return el?.account_id;
    });

    setCurrentSelectedAccount(selectedAccount);
    setTableData(mappedData);
  };

  React.useEffect(() => {
    initData();
  }, []);
  //
  const columns: Array<Column<IProductPricingItem>> = [
    {
      title: 'Value',
      field: 'value',
      type: 'numeric',
    },
    {
      title: 'Account',
      field: 'account_id',
      render: (rowData) => rowData.account_label,
      editComponent(props) {
        return (
          <Autocomplete
            options={record.account_list.filter(
              (dt: any) => !currentSelectedAccount.includes(dt.id)
            )}
            disablePortal
            id="account-search-input"
            value={
              props.rowData?.account_id === props.rowData?.account_id_data?.id ? props.value : null
            }
            onChange={(a, b) => props.onChange(b.id)}
            getOptionLabel={(option) => option.label || props.rowData.account_label || ''}
            sx={{ width: 250 }}
            renderInput={(params) => <TextField {...params} label="account" />}
          />
        );
      },
    },
    {
      title: 'Method',
      field: 'method',
      lookup: {
        ABSOLUTE: 'ABSOLUTE',
      },
    },
    {
      title: 'Available',
      field: 'is_available',
      render: (rowData) => (rowData.is_available ? <CheckIcon /> : <ClearIcon />),
      type: 'boolean',
      lookup: { true: 'TRUE', false: 'FALSE' },
    },
  ];

  if (!record) return null;
  return (
    <>
      <Confirm
        isOpen={cOpen}
        loading={otpCode.length < 6 ? true : isLoading}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <MaterialTable
        columns={columns}
        data={tableData}
        title={`${record.label}`}
        editable={{
          onRowAdd: async (newData) => {
            if (Object.keys(newData).length === 0) {
              Swal.fire('There is no data to be added!', '', 'error');
            } else {
              const { account_id, is_available, method, value } = newData;
              //
              setOtpCode('');
              setSubmitConf({
                method: 'add-product-pricing',
                data: {
                  product_item_id: record.id,
                  account_id: account_id,
                  method: method,
                  value: value,
                  is_available: is_available,
                },
              });
              setCOpen(true);
            }
          },
          onRowUpdate: async (newData, oldData) => {
            console.log(234, {
              oldData,
              newData,
            });
            // compare old data with new data and send the new one
            const changes = differenceWith(toPairs(newData), toPairs(oldData), isEqual);
            const changes2 = fromPairs(changes);
            //
            if (changes2.is_available) {
              changes2.is_available = changes2.is_available.toLowerCase() === 'true' ? true : false;
            }
            //
            if (Object.keys(changes2).length === 0) {
              Swal.fire('There is no data to be updated!', '', 'error');
            } else {
              setOtpCode('');
              setSubmitConf({
                method: 'update-product-pricing',
                data: {
                  dataProductPricing: {
                    ...changes2,
                  },
                  product_pricing_id: newData.product_pricing_id,
                  oldData: oldData,
                },
              });
              setCOpen(true);
            }
          },
          onRowDelete: async (oldData) => {
            setOtpCode('');
            setSubmitConf({
              method: 'delete-product-pricing',
              data: {
                product_pricing_id: oldData.product_pricing_id,
                oldData: oldData,
              },
            });
            setCOpen(true);
          },
        }}
      />
    </>
  );
};

export default ProductPricing;
